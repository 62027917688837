<!-- Mto particular de Funerarias Sucursales -->

<template>
  <div class="funes_M" v-if="schema"
       style="align-self:start">
  
      <!-- Cabecera -->
      <div class="cabecera">
        <base_Header
          v-if="Entorno.header.header"       
          :Entorno="Entorno.header"
          @onEvent="event_Header">
        </base_Header>
      </div> 
      <v-sheet :elevation="4">
      <!-- Botones Mto -->
      <div class="pt-2 d-flex justify-left" style="justify-content:space-between;width:320px">
        <!-- Mto -->            
        <btramto
            :btra_permisos="schema.btra"
            :btra_particular="Entorno.btra"
            btra_modulo="m"
            :estado="estado"
            @onEvent="event_btra_Mto">        
        </btramto>

        <!-- Ventana de Documentos -->       
        <common_Docs          
          :id="record.id"
          :show="schema.btra.extra.docs"
          tip="1">
        </common_Docs>

        <!-- Ventana de Facturas -->
        <common_Fras          
          :id="record.id"
          :show="schema.btra.extra.fras && app==1"
          tip="1">
        </common_Fras>
        
        <!-- Extra -->            
        <btraextra
            :btra_permisos="schema.btra"
            :btra_particular="Entorno.btra"
            btra_modulo="mextra"
            @onEvent="event_btra_Extra">     
        </btraextra>            
      </div>
  
      <!-- Controles del Mto -->
      <div class="ctrls conflex contenedor"
           style="justify-content:center">      
        <div class="columna">
          <v-sheet width="500px" v-bind="$cfg.styles.marco">
            <ctrlcontact
              :schema="schema"
              :name="schema.ctrls.name"
              :edicion="!apdsEdit">
            </ctrlcontact>
          </v-sheet>

          <div class="cab">CENTRAL</div>

          <v-sheet width="500px" v-bind="$cfg.styles.marco">
            <ctrlfinder
              finderName="funec_F"
              :schema="schema.ctrls.idc"
              :edicion="!apdsEdit">
            </ctrlfinder>
         
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.grupo.value"
              :label="schema.ctrls.grupo.label"
              :disabled="true">
            </v-text-field>
           </v-sheet>
        </div>

        <div class="columna">
          <div class="cab">CLASIFICACIÓN</div>
          <v-sheet width="300px" v-bind="$cfg.styles.marco">
            <v-select
              v-bind="$select"
              :items="itemsSituacion"
              :label="schema.ctrls.situ.label"              
              :disabled="apdsEdit"
              item-value="id"
              item-text="label"
              v-model="schema.ctrls.situ.value">
            </v-select>

            <v-select
              v-bind="$select"
              :items="itemsTipo"
              :label="schema.ctrls.tipo.label"
              :disabled="apdsEdit"
              item-value="id"
              item-text="name"
              v-model="schema.ctrls.tipo.value">
            </v-select>
          </v-sheet>

          <v-sheet width="300px" v-bind="$cfg.styles.marco">
            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.ref_cia.value"
              :label="schema.ctrls.ref_cia.label"
              :disabled="apdsEdit">
            </v-text-field>

            <v-text-field
              v-bind="$input"
              v-model="schema.ctrls.ccont.value"
              :label="schema.ctrls.ccont.label"
              :disabled="apdsEdit">
            </v-text-field>
          </v-sheet>

          <v-sheet v-show="this.app==1" width="280px" v-bind="$cfg.styles.marco">
            <div style="display:flex">
              <v-text-field
                v-bind="$input"
                v-model="schema.ctrls.tcs.value"
                :label="schema.ctrls.tcs.label"
                :disabled="true">
              </v-text-field>

              <v-btn                
                class="white--text ml-2" 
                :disabled="apdsEdit"  
                v-bind="$cfg.btra_cfg.standard" 
                @click="cs()">
                  CS
              </v-btn>
            </div>

            <div style="display:flex;justify-content:space-between;width:150px">
              <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.tpe.value"
                :label="schema.ctrls.tpe.label"
                :disabled="apdsEdit">
              </v-checkbox>
            
              <v-checkbox
                v-bind="$checkbox"
                v-model="schema.ctrls.pdi.value"
                :label="schema.ctrls.pdi.label"
                :disabled="apdsEdit">
              </v-checkbox>
            </div>

            <div class="conflex">
              <v-select
                style="width:77%"
                v-bind="$select"
                v-model="schema.ctrls.tRF.value"
                :label="schema.ctrls.tRF.label"
                :items="itemsFiscal"                
                :disabled="apdsEdit"
                item-value="id"
                item-text="name">                
              </v-select>

              <v-text-field
                style="width:20%;margin-left:5px"
                v-bind="$input"
                v-model="schema.ctrls.pRF.value"
                :label="schema.ctrls.pRF.label"
                :disabled="apdsEdit">
              </v-text-field>
            </div>
          </v-sheet>            
        </div>

        <div class="columna" style="width:780px">
          <div v-show="app==1">
            <ctrlobs3                          
              :schemaComponente="schema.ctrls.obs"
              :schema="schema"
              :record="record"
              :edicion="!apdsEdit">
            </ctrlobs3>
          </div>

          <ctrlobs3            
            :schemaComponente="schema.ctrls.obsjz"
            :schema="schema"
            :record="record" 
            :edicion="app==2 && is_edit">
          </ctrlobs3>
        </div>
      </div>
  </v-sheet>  
      <div>
        <v-tabs
          v-model="tabOpcion"
          background-color="blue-grey darken-1"
          active-class="blue-grey lighten-3 font-weight-bold"
          :align-with-title="true"
          dark
          show-arrows
          hide-slider
          height="25px">        
            <v-tab v-for="seccion in secciones" :key="seccion.titulo">
              <v-icon class="pr-2" small>{{ seccion.icono }}</v-icon>
              {{ seccion.titulo }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tabOpcion">
          <v-tab-item
            v-for="seccion in secciones"
            :key="seccion.titulo"
            :transition="false"
            :reverse-transition="false">
          
              <div
                class="row-principal  pt-3 pl-2"
                style="background-color: #eee;">                
                  <component
                    v-if="$store.state[stName].record"
                    :is="seccion.comp"                    
                    :padre="stName"
                    :id="$store.state[stName].record.id"
                    :disparoFinder="disparoFinder">
                  </component>
              </div>
          </v-tab-item>
        </v-tabs-items>
      </div>
           
  </div>
</template>


<script>
 
  import { mixinMto } from "@/mixins/mixinMto.js";
  import plugs from "@/common/general_plugs";
  const base_Header = () => plugs.groute("base_Header.vue", "base");
  const btramto = () => plugs.groute("btramto_new.vue", "comp");
  const btraextra = () => plugs.groute("btraextra.vue", "comp");
  const ctrlcontact = () => plugs.groute("ctrlcontact.vue", "comp");
  const ctrlobs3 = () => plugs.groute("ctrlobs3.vue", "comp");
  const ctrlfinder = () => plugs.groute("ctrlfinder.vue", "comp");
  const funes_M_datos = () => plugs.groute("funes_M_datos.vue", "comp");
  const funes_pres_F = () => plugs.groute("funes_pres_F.vue", "comp");
  const common_Docs = () => plugs.groute("common_Docs.vue", "comp");
  const common_Fras = () => plugs.groute("common_Fras.vue", "comp");

  export default {
    mixins: [mixinMto],
    components: {  
      base_Header,
      btramto,
      btraextra,
      ctrlcontact,
      ctrlobs3,
      ctrlfinder,
      funes_M_datos,
      funes_pres_F,
      common_Docs,
      common_Fras
    },

    props: {      
      padre: { type:String, default: '' }, 
      accion: { type:[Number, String], default: ''},
      accionRow: { type:Object, default: ()=> {}},
      disparo: { type:Boolean, default:false },
      componenteTipo: { type:String, default:'M' },
      Entorno: { type:Object, default: function() { return JSON.parse(JSON.stringify(this.$cfg.base.M)); }}
    },

    data() {
      return {
        schema: null,
        api:'funes_M',
        stName:'stMfunes',
       
        tabOpcion: null,
        secciones: [
          { titulo: "Datos", icono: "mdi-phone",comp: "funes_M_datos" },
          { titulo: "Presupuestos",icono: "mdi-filter",comp: "funes_pres_F" }
        ],

        itemsSituacion:[],
        itemsTipo:[],
        itemsFiscal:[],
        disparoFinder:false,
        Entorno_dinamico:{},
        componente_dinamico:null
      };
    },

    // secuencia del componente:
    //  mixinMto:
    //        1 create()
    //             2   ini_data()=>funes_M  (Inicializo datos reactivos y lo necesario para cargar schema(API))
    //             3   this.schema = await ... (cargo schema });
    //             4   ini_SchemaCtrls()=>funes_M ( ya tengo el schema y sigo la secuencia con ya todo lo necesario)
    //                                 this.component_base="base_M"; (lanzo el componente base hijo)
    //                                                                 (elimino if schema del template, no hace falta)
    //             5 await this.acciones_Mto({ accion: this.accion });  
    //             6 se pinta el componente  

    computed: {
      apdsEdit() {
        if  (this.app=='2') return true;
        return !this.is_edit;
      }
    },


    methods: {

      ini_data(){

        // guardo del store en variables locales
        this.schema = this.$store.state[this.stName].schema;        
        
        // configuro Entorno 
        this.Entorno.header.titulo = "Mantenimiento Funerarias Sucursales"; 
        this.Entorno.btra = JSON.parse(JSON.stringify(this.$cfg.btra_tipo.standardM));
               
        // obtengo items adicionales
        this.itemsSituacion= this.$store.state.datos_iniciales.situ;
        this.itemsTipo= this.$store.state.datos_iniciales.tipo;  
        this.itemsFiscal= this.$store.state.datos_iniciales.tipo_regfiscal;
      },


      record_cargado(){ 
        this.btnSet(3,{view:true, disabled:this.app!=1})    
        this.btnSet(1,{view:true, disabled:this.app!=1});
      },


      // descargo el contrato de la funeraria
      view_contrato(){
        console.log("muestro contrato");
      },

      //------------------------------------------------

      // tramitación capital sobrante
      cs()
      {       
          var txt= Number(this.schema.ctrls.tcs.value)=== 10? 'ELIMINAMOS' : 'CONFIRMAMOS';
          this.$root.$alert.open("¿" + txt + " 10% de Tramitación CS?", "confirm").then(r => {
            if (r) this.cs_continue()          
          });
      },

      cs_continue()
      {
          if (Number(this.schema.ctrls.tcs.value)=== 10) {
              this.schema.ctrls.tcs.value= 0;
              this.schema.ctrls.obs.value= "[PRUEBA 10:00 25/06/2020] Eliminado porcentaje Tramitación CS " + this.schema.ctrls.tcs.value + "%\n" + this.schema.ctrls.obs.value;            
              return;
          }

          this.schema.ctrls.tcs.value= 10;
          this.schema.ctrls.obs.value= "[PRUEBA 10:00 25/06/2020] Añadido porcentaje Tramitación CS " + this.schema.ctrls.tcs.value + "%\n" + this.schema.ctrls.obs.value;                
      }
    }
  };
</script>
